import React from "react"
import MuiAlert, { AlertProps } from "@mui/material/Alert"

export interface Props extends AlertProps {
  /* MindPort Props  */
}

export const Alert: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return <MuiAlert {...props}>{children}</MuiAlert>
}

Alert.defaultProps = {
  sx: {
    mb: 2,
    mt: 2,
  },
}

export default Alert
