import React from "react"
import CssBaseline from "@mui/material/CssBaseline"

import GlobalStyle from "@/components/GlobalStyle"
import { Outlet } from "react-router-dom"
import { AppContent, Root, Box } from "./StoryLayout.styled"
import { Helmet } from "react-helmet-async"
import { useStoryFolder } from "@/hooks/useStoryFolder"

const StoryLayout: React.FC = () => {
  const folder = useStoryFolder()
  return (
    <Root>
      <Helmet title="MetaPerverse - A Short Story by MindPort" />
      <CssBaseline />
      <GlobalStyle />
      <AppContent folder={folder}>
        <Box>
          <Outlet />
        </Box>
      </AppContent>
    </Root>
  )
}

export default StoryLayout
