import React from "react"
import { Search as SearchIcon } from "react-feather"
import { useTranslation } from "react-i18next"
import { withTheme } from "styled-components"

import { Grid, Hidden, Toolbar } from "@mui/material"
import { Menu as MenuIcon } from "@mui/icons-material"

import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown/NavbarLanguagesDropdown"
import NavbarUserDropdown from "./NavbarUserDropdown/NavbarUserDropdown"

import {
  AppBar,
  IconButton,
  Input,
  Search,
  SearchIconWrapper,
} from "./Navbar.styled"

export type NavbarProps = {
  theme: {}
  onDrawerToggle: React.MouseEventHandler<HTMLElement>
}

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { t } = useTranslation()
  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item></Grid>
            <Grid item xs />
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search by Nº")} type="search" readOnly />
              </Search>
            </Grid>
            <Grid item>
              <NavbarUserDropdown />
              <NavbarLanguagesDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default withTheme(Navbar)
