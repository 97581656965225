import MuiTextField from "./Textfield.styled"
import { OutlinedTextFieldProps } from "@mui/material/TextField"

// (JSX attribute) onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
const TextField = (props: OutlinedTextFieldProps) => (
  <MuiTextField {...(props as any)} />
)

TextField.defaultProps = {
  size: "small",
  variant: "outlined",
  label: "Textfield",
}

export default TextField
