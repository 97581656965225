import { useMediaQuery } from "@mui/material"

export const useStoryFolder = () => {
  const matches400 = useMediaQuery("(max-width:400px)")
  const matches600 = useMediaQuery("(max-width:600px)")
  const matches960 = useMediaQuery("(max-width:960px)")

  if (matches400) {
    return "tiny"
  }

  if (matches600) {
    return "small"
  }

  if (matches960) {
    return "medium"
  }

  return "large"
}
