import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"

interface Props {
  lang: string
}

const LanguageLayout: React.FC<Props> = ({ lang }) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [i18n, lang])

  return <Outlet />
}

export default LanguageLayout
