import TextField from "@mui/material/TextField"
import styled from "styled-components"

export const MuiTextField = styled(TextField)`
  && {
    margin: 0;
    .MuiTextfield-colorPrimary fieldset {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
    }
    .MuiTextfield-colorPrimary.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.palette.primary.main};
    }
  }
`

export default MuiTextField
