import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import styled from "styled-components"

const MuiCard = styled(Card)((props) => ({
  "&&": {
    borderRadius: 0,
    border: props.theme.style.card.border,
    paddingBottom: 0,

    "&:not(:last-of-type)": {
      marginBottom: 16,
    },
  },
}))

const MuiCardHeader = styled(CardHeader)(({ theme: { style } }) => ({
  "&&": {
    backgroundColor: style.cardHeader.backgroundColor,
    color: style.cardHeader.color,
    padding: style.padding.medium + " " + style.padding.large,

    ".MuiCardHeader-action": {
      margin: 0,
      a: {
        textDecoration: "none",
        color: "#ff8a8a",
        fontWeight: "bold",
        "&:hover": {
          color: "#fff",
        },
      },
    },

    ".MuiCardHeader-content > span": {
      fontSize: style.fontSize.medium,
    },
  },
}))

const MuiCardContent = styled(CardContent)(({ theme: { style } }) => ({
  "&&": {
    backgroundColor: style.cardContent.backgroundColor,
    color: style.cardContent.color,
    padding: style.padding.medium + " " + style.padding.large,

    "&:last-child": {
      paddingBottom: style.padding.medium,
    },
  },
}))

const MuiCardActions = styled(CardActions)(({ theme: { style } }) => ({
  "&&": {
    backgroundColor: style.cardActions.backgroundColor,
    color: style.cardActions.color,
    padding: style.padding.small + " " + style.padding.medium,
  },
}))

export { MuiCard, MuiCardHeader, MuiCardContent, MuiCardActions }
