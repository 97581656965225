import styled from "styled-components"

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`

const AppContent = styled("div")<{ folder: string }>(({ folder }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(/assets/img/story-${folder}/bkg01.webp)`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}))

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export { Root, AppContent, Box }
