import merge from "deepmerge"
import { green, grey, red } from "@mui/material/colors"

import { THEMES } from "@/constants"

const defaultVariant = {
  name: THEMES.DEFAULT,
  style: {
    borderColor: "#e0e0e0",
    padding: {
      tiny: "2px",
      small: "4px",
      medium: "8px",
      large: "16px",
      xlarge: "24px",
      huge: "32px",
    },
    fontSize: {
      tiny: "10px",
      small: "12px",
      medium: "14px",
      large: "16px",
      xlarge: "20px",
      huge: "24px",
    },
    card: {
      border: "1px solid #e0e0e0",
      borderColor: "#e0e0e0",
    },
    cardHeader: {
      backgroundColor: "#000",
      color: "#fff",
    },
    cardContent: {
      backgroundColor: "#fff",
      color: "#000",
    },
    cardActions: {
      backgroundColor: "#e6e6e6",
      color: "#000",
    },
    table: {
      borderColor: "#e0e0e0",
      thead: {
        backgroundColor: "#f0f0f0",
      },
    },
    link: {
      color: "#1EA7FD",
      hover: "#8F0D00",
    },
  },
  palette: {
    global: {
      inputLabel: "#555",
    },
    progress: {
      background: grey[300],
      color: red[700],
      primary: red[700],
      secondary: grey[800],
      success: green[700],
    },
    primary: {
      main: "#e61b00",
      over: red[900],
      contrastText: "#FFF",
    },
    secondary: {
      main: grey[500],
      over: grey[700],
      contrastText: "#FFF",
    },
    dataGrid: {
      header: {
        color: "#4f4f4f",
        background: "#F0F0F0",
      },
      status: {
        processing: "#f7be5d",
        waiting: "#c35141",
      },
    },
    fieldset: {
      borderColor: grey[300],
      textColor: grey[900],
      labelColor: grey[700],
    },
    error: red,
    background: {
      default: grey[200],
      paper: "#FFF",
    },
    logo: {
      color: "#ed1c24",
      textColor: "#231f20",
    },
    status: {
      default: {
        background: grey[200],
        color: grey[800],
      },
      selected: {
        background: grey[300],
        color: grey[800],
      },
      focus: {
        background: grey[400],
        color: grey[800],
      },
    },
  },
  header: {
    color: grey[500],
    background: "#fff",
    search: {
      color: grey[800],
    },
    indicator: {
      background: red[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#fff",
  },
  sidebar: {
    color: "#fff",
    background: "#000",
    iconSize: 32,
    header: {
      color: grey[200],
      background: grey[100],
      brand: {
        color: grey[200],
      },
    },
    footer: {
      color: grey[200],
      background: grey[100],
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: red[500],
    },
  },
  status: {
    default: grey[200],
    selected: grey[300],
    focus: grey[400],
  },
  typography: {
    fontWeightMedium: 600,
    fontFamily: "Arial",
  },
}

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "Default",
    primary: {
      main: grey[700],
      over: grey[900],
      contrastText: "#FFF",
    },
    secondary: {
      main: grey[500],
      over: grey[700],
      contrastText: "#FFF",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
    background: {
      default: grey[700],
      paper: "#f6f6f6",
    },
    logo: {
      color: "#444",
      textColor: "#666",
    },
  },
  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
})

export const variants: Array<VariantType> = [defaultVariant, darkVariant]

export { darkVariant, defaultVariant }

export default variants

export type VariantType = {
  name: string
  style: {
    padding: {
      small: string
      medium: string
      large: string
    }
    card: {
      border: string
    }
    cardHeader: {
      backgroundColor: string
      color: string
    }
    cardContent: {
      backgroundColor: string
      color: string
    }
    cardActions: {
      backgroundColor: string
      color: string
    }
  }
  palette: {
    primary: MainContrastTextType
    secondary: MainContrastTextType
    status: {
      default: ColorBgType
      selected: ColorBgType
      focus: ColorBgType
    }
  }
  header: ColorBgType & {
    search: {
      color: string
    }
    indicator: {
      background: string
    }
  }
  footer: ColorBgType
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string
      }
    }
    footer: ColorBgType & {
      online: {
        background: string
      }
    }
    badge: ColorBgType
  }
}

type MainContrastTextType = {
  main: string
  over: string
  contrastText: string
}

type ColorBgType = {
  color: string
  background: string
}
