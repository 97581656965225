import { useMediaQuery } from "@mui/material"

export const useWidthSize = () => {
  const matches400 = useMediaQuery("(max-width:400px)")
  const matches414h = useMediaQuery("(max-height:414px)")
  const matches600 = useMediaQuery("(max-width:600px)")
  const matches900 = useMediaQuery("(max-width:900px)")
  const matches1200 = useMediaQuery("(max-width:1200px)")

  if (matches400 || matches414h) {
    return "70%"
  }

  if (matches600) {
    return "85%"
  }

  if (matches900) {
    return "85%"
  }

  if (matches1200) {
    return "85%"
  }

  return "85%"
}

export const useIsMobile = () => {
  const matches501 = useMediaQuery("(min-width:501px)")

  if (matches501) {
    return false
  }

  return true
}
