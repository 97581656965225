import SvgIcon from "@mui/material/SvgIcon"
import styled from "styled-components"

const MuiSvgIcon = styled(SvgIcon)(({ theme: { palette } }) => ({
  "&&": {
    color: palette.primary.main,
  },
}))

export default MuiSvgIcon
