import React from "react"
import { Link } from "react-router-dom"
import { LogoStyled, LogoStyledNavBar } from "./Logo.styled"

interface LogoMindPortProps {
  width?: string
  height?: string
  navbar?: boolean
}

const LogoSvg: React.FC<LogoMindPortProps> = ({
  width = "143px",
  height = "80px",
  navbar,
}) => {
  const viewBoxLogo = navbar ? "0 0 400 400" : "0 0 945.32 323.22"
  const LogoStyledToUse = navbar ? LogoStyledNavBar : LogoStyled
  return (
    <LogoStyledToUse>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBoxLogo}
        width={width}
        height={height}
      >
        {!navbar && (
          <defs>
            <linearGradient
              id="Fade_to_Black"
              x1="131.42"
              y1="447.34"
              x2="131.42"
              y2="121.7"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#231f20" />
              <stop offset="0.06" stopColor="#231f20" stopOpacity="0.86" />
              <stop offset="0.33" stopColor="#231f20" stopOpacity="0.38" />
              <stop offset="0.63" stopColor="#231f20" stopOpacity="0.09" />
              <stop offset="1" stopColor="#231f20" stopOpacity="0" />
            </linearGradient>
          </defs>
        )}
        <g className="cls-1">
          <g id="mindport_logo" data-name="mindport logo">
            <path
              className="cls-2"
              d="M131.44,101.63A106.48,106.48,0,0,0,89.88,110L24.6,4V209.19l0,0A106.8,106.8,0,1,0,131.44,101.63Zm0,146.09a36.58,36.58,0,0,1-36.59-36.36l0,0V141.06l22.37,36.35a36.59,36.59,0,1,1,14.24,70.31Z"
            />
            {!navbar && (
              <g className="logo-text">
                <path
                  className="cls-4"
                  d="M131.44,101.63A106.48,106.48,0,0,0,89.88,110L24.6,4V209.19l0,0A106.8,106.8,0,1,0,131.44,101.63Zm0,146.09a36.58,36.58,0,0,1-36.59-36.36l0,0V141.06l22.37,36.35a36.59,36.59,0,1,1,14.24,70.31Z"
                />
                <path
                  className="cls-5"
                  d="M365.28,161.67c-.85,0-1.72,0-2.56.05A56.29,56.29,0,0,0,325,177.18V149.3l-20.27-33V222.18c-.41,16.6,5.6,31.17,17.88,43.32C334.3,277.1,348.22,283,363.93,283c.71,0,1.42,0,2.14,0a57.66,57.66,0,0,0,41.06-18.48,59.08,59.08,0,0,0,16.84-42c0-16.7-6.09-31.31-18.11-43.44C394.43,167.5,380.78,161.67,365.28,161.67Zm38.42,59.72a40.24,40.24,0,0,1-11,28.8c-7.47,8.18-16.32,12.4-27.07,12.88-.62,0-1.24,0-1.85,0h0c-10.54,0-19.44-3.9-27.19-11.9-8.22-8.53-12-18.32-11.68-30a39.83,39.83,0,0,1,11.68-28c7.57-7.79,16.41-11.59,27-11.59a37.7,37.7,0,0,1,28.11,11.7A40,40,0,0,1,403.7,221.39Z"
                />
                <path
                  className="cls-5"
                  d="M474.8,166.7a35.41,35.41,0,0,0-12.47,10.9l-16.52-26.76v129.6h21.05V211.59c0-6.59,1.12-11.85,3.28-15.62,1.4-2.32,4.13-5.15,8.11-8.4,3.76-3.08,8.82-4.76,15-5l2.67-.1v-20l-2.65-.12A35.32,35.32,0,0,0,474.8,166.7Z"
                />
                <path
                  className="cls-5"
                  d="M573.39,161.72c-16.64-.55-31.13,5.32-43.13,17.39a59.81,59.81,0,0,0-18,43.52,59.19,59.19,0,0,0,16.75,42,56.52,56.52,0,0,0,41,18.3c.73,0,1.44,0,2.16,0,15.71,0,29.52-5.86,41.07-17.41,12.05-12,18.16-26.56,18.16-43.14a59.38,59.38,0,0,0-16.85-42.12C603.26,168.52,589.45,162.26,573.39,161.72ZM611,221.33c.25,11.55-3.61,21.35-11.77,30-7.55,8-16.29,11.84-26.73,11.84-.75,0-1.5,0-2.32-.06-10.73-.37-19.55-4.56-27-12.81a39.87,39.87,0,0,1-10.76-28.76,39.19,39.19,0,0,1,11.83-28.35,37.1,37.1,0,0,1,27.6-11.54h.54c10.57,0,19.35,3.8,26.83,11.61A41.33,41.33,0,0,1,611,221.33Z"
                />
                <path
                  className="cls-5"
                  d="M751.4,243.64c-8.08,12.83-20,19.19-36.31,19.43h-.52c-11.16,0-20.56-3.9-28.77-11.92s-12.37-17.63-12.37-29,4.34-20.91,13.3-29.25c8.59-8.11,18-11.9,28.71-11.68,16.19.38,27.88,7.15,35.72,20.71l.8,1.39h22.55l-3.86-8.78C766.3,185,759,177.07,749,170.85a62.82,62.82,0,0,0-33.33-9.52H715a62.26,62.26,0,0,0-43.85,18.34c-12.2,11.91-18.25,26.45-18,43.18s6.36,30.88,18.49,42.49A61.57,61.57,0,0,0,715,283h.93c24.81,0,43.45-11.19,55.48-33.37l3.41-7.25h-22.6Z"
                />
                <path
                  className="cls-5"
                  d="M897.88,180.31a58.34,58.34,0,0,0-41.41-18.59c-16.79-.65-31.34,5.11-43.36,17.19s-18.05,26.7-18.05,43.53a59.21,59.21,0,0,0,16.78,42C823,276.2,836.69,282.42,852.56,283c17,.55,31.61-5.51,43.63-17.93l18,28.92v-71.6C914.71,206.26,909.23,192.11,897.88,180.31Zm-4,40.92a40.24,40.24,0,0,1-11.75,30c-7.66,8-16.56,11.9-27.18,11.9-.62,0-1.24,0-1.86,0-10.59-.48-19.4-4.73-26.93-13a39.51,39.51,0,0,1-10.82-28.7,38.64,38.64,0,0,1,11.8-28.16,38,38,0,0,1,28.15-11.62c10.58,0,19.4,3.8,27,11.59A40.31,40.31,0,0,1,893.88,221.23Z"
                />
              </g>
            )}
          </g>
        </g>
      </svg>
    </LogoStyledToUse>
  )
}

const Logo: React.FC<LogoMindPortProps> = ({ ...props }) => (
  <Link to="/" aria-label="Logo MindPort">
    <LogoSvg {...props} />
  </Link>
)

export default Logo
