import { ButtonProps } from "@mui/material/Button"
import MuiButton from "./Button.styled"

export const Button = (props: ButtonProps) => {
  return (
    <MuiButton {...props} role="button">
      {props.children}
    </MuiButton>
  )
}

Button.defaultProps = {
  type: "button",
  variant: "contained",
  disableElevation: true,
  color: "primary",
  size: "small",
  children: "button",
}

export default Button
