import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { ThemeProvider } from "styled-components"

import MuiThemeProvider from "@mui/material/styles/ThemeProvider"
import StyledEngineProvider from "@mui/styled-engine-sc/StyledEngineProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import {
  PresentationLayout,
  StoryLayout,
  LanguageLayout,
} from "@/components/layouts"
import { ThemeProvider as ThemeContextProvider } from "@/contexts/ThemeContext"
import createTheme from "@/theme"
import useTheme from "@/hooks/useTheme"
import * as Pages from "@/pages"

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"

import "./vendor/perfect-scrollbar.css"
import "@/i18n"

const ThemeSwitcher: React.FC = () => {
  const { theme } = useTheme()

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <ThemeProvider theme={createTheme(theme)}>
        <Outlet />
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

const AppLayout: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s" defaultTitle="MindPort" />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeContextProvider>
            <ThemeSwitcher />
          </ThemeContextProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </HelmetProvider>
  )
}

interface PageData {
  index: number
  mp3Id?: string
  position: string
  color: string
}

const pagesData: PageData[] = [
  {
    index: 1,
    position: "middleLarge",
    color: "white",
  },
  {
    index: 2,
    mp3Id: "13J7wIpBR-6-SFP3-EluaI4ES0CApLXRW",
    position: "centered",
    color: "white",
  },
  {
    index: 3,
    mp3Id: "19CNp3Gjq0xqTLDr8-5L43QH_eYdvaQUq",
    position: "middle",
    color: "black",
  },
  {
    index: 4,
    mp3Id: "1SkVlo7L2XKW9ANii27CxntLTBnjkeVP_",
    position: "centeredTop",
    color: "white",
  },
  {
    index: 5,
    mp3Id: "1hZv0f2xzrQDaoQV5dgHbuosf3-kEb7SB",
    position: "centeredTop",
    color: "white",
  },
  {
    index: 6,
    mp3Id: "1cMGUiO4eZ_Fu-fxaCzueUP5jJnONRNEE",
    position: "middle",
    color: "black",
  },
  {
    index: 7,
    mp3Id: "1gqcizy4MUUvheuYtZQx8ZI3jE2RqklqU",
    position: "centered",
    color: "white",
  },
  {
    index: 8,
    mp3Id: "1OSPGMmZ7m9EbWsH9pdfrwciJdDyObAhV",
    position: "left",
    color: "black",
  },
  {
    index: 9,
    mp3Id: "1688bKWb87qF_vhDdrhL2nb97rgTWp3SL",
    position: "left",
    color: "black",
  },
  {
    index: 10,
    mp3Id: "1Ecbg7der8tOKNFywhIWsHHPIWmGhpYr2",
    position: "right",
    color: "black",
  },
  {
    index: 11,
    position: "centeredTop",
    color: "white",
  },
  {
    index: 12,
    mp3Id: "1CXOm18kwf3kGmK753343_Xpm_OBboMGs",
    position: "centeredTop",
    color: "white",
  },
]

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route element={<PresentationLayout />}>
            <Route index element={<Pages.Home />} />
            <Route path="home" element={<Pages.Home />} />
          </Route>
          <Route path="story" element={<StoryLayout />}>
            <Route index element={<Pages.Story />} />
            <Route path="fr" element={<LanguageLayout lang="fr" />}>
              {pagesData.map((page) => {
                return (
                  <Route
                    key={`${page.index}`}
                    path={`${page.index}`}
                    element={
                      <Pages.PageMarkdown
                        index={page.index}
                        lang="fr"
                        mp3Id={page.mp3Id}
                        position={page.position}
                        color={page.color}
                      />
                    }
                  />
                )
              })}
            </Route>
            <Route path="en" element={<LanguageLayout lang="en" />}>
              {pagesData.map((page) => {
                return (
                  <Route
                    key={`${page.index}`}
                    path={`${page.index}`}
                    element={
                      <Pages.PageMarkdown
                        index={page.index}
                        lang="en"
                        mp3Id={page.mp3Id}
                        position={page.position}
                        color={page.color}
                      />
                    }
                  />
                )
              })}
            </Route>
            <Route path="credits" element={<Pages.Credits />} />
          </Route>
        </Route>
        <Route path="*" element={<Pages.Page404 />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
