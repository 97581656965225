import React, { SyntheticEvent, useId, useState } from "react"
import { Power } from "react-feather"
import styled from "styled-components"

import {
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Tooltip,
} from "@mui/material"

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const NavbarUserDropdown: React.FC = () => {
  const [anchorMenu, setAnchorMenu] = useState<any>(null)
  const menuId = useId()

  const toggleMenu = (event: SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? menuId : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id={menuId}
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Profile</MenuItem>
      </Menu>
    </>
  )
}

export default NavbarUserDropdown
