import { useMediaQuery } from "@mui/material"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

interface TextColorProps {
  color: string
  fontSize: number
  width: string
  left: string
  top: string
}

const MarkdownContainerCentered = styled("div")<TextColorProps>(
  ({ color, fontSize, width, left, top }) => ({
    left: left,
    top: top,
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

const MarkdownContainerCenteredTop = styled("div")<TextColorProps>(
  ({ color, fontSize, top, width, left }) => ({
    left: left,
    top: top,
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

const MarkdownContainerMiddle = styled("div")<TextColorProps>(
  ({ color, fontSize, top, width, left }) => ({
    left: left,
    top: top,
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

const MarkdownContainerMiddleLarge = styled("div")<TextColorProps>(
  ({ color, fontSize, width, left, top }) => ({
    left: left,
    top: top,
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

type CenterLeftProps = TextColorProps & { top: string }

const MarkdownContainerLeft = styled("div")<CenterLeftProps>(
  ({ color, fontSize, top, width, left }) => ({
    left: left,
    top: top,
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

const MarkdownContainerRight = styled("div")<TextColorProps>(
  ({ color, fontSize, top, width, left }) => ({
    right: left,
    top: top,
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

const MarkdownContainerBottomLeft = styled("div")<TextColorProps>(
  ({ color, fontSize, width }) => ({
    left: "50%",
    top: "50%",
    "-webkit-transform": "translate(-50%, -50%)",
    "-moz-transform": "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    zIndex: 800,
    color: color,
    width: width,
    fontSize: fontSize,
  })
)

interface MarkdownPositionedProps {
  position: string
  text: string
  color: string
}

const useFontSize = () => {
  const matches400 = useMediaQuery("(max-width:400px)")
  const matches414h = useMediaQuery("(max-height:414px)")
  const matches600 = useMediaQuery("(max-width:600px)")
  const matches900 = useMediaQuery("(max-width:900px)")
  const matches1200 = useMediaQuery("(max-width:1200px)")

  if (matches400) {
    return 4
  }

  if (matches600 || matches414h) {
    return 6
  }

  if (matches900) {
    return 8
  }

  if (matches1200) {
    return 12
  }

  return 18
}

const useTopPos = (type: string) => {
  const matches360 = useMediaQuery("(max-width:360px)")
  const matches375 = useMediaQuery("(max-height:375px)")
  const matches390 = useMediaQuery("(max-width:390px)")
  const matches390h = useMediaQuery("(max-height:390px)")
  const matches400 = useMediaQuery("(max-width:400px)")
  const matches414 = useMediaQuery("(max-width:414px)")
  const matches414h = useMediaQuery("(max-height:414px)")
  const matches600 = useMediaQuery("(max-width:600px)")
  const matches667 = useMediaQuery("(max-width:667px)")
  const matches768 = useMediaQuery("(max-width:768px)")
  const matches820 = useMediaQuery("(max-width:820px)")
  const matches900 = useMediaQuery("(max-width:900px)")
  const matches1200 = useMediaQuery("(max-width:1200px)")

  if (type === "centered") {
    if (matches360) {
      return "44%"
    }
    if (matches400 || matches375 || matches390 || matches390h || matches360) {
      return "43%"
    }
    if (matches414) {
      return "43%"
    }
    if (matches667) {
      return "40%"
    }
    if (matches768 || matches820) {
      return "45%"
    }
    return "50%"
  } else if (type === "centeredTop") {
    if (matches390h || matches390) {
      return "45%"
    }
    if (matches400 || matches375 || matches390) {
      return "42%"
    }

    if (matches600) {
      return "45%"
    }

    if (matches900) {
      return "45%"
    }

    if (matches1200) {
      return "45%"
    }
    return "40%"
  } else if (type === "middle") {
    if (matches400 || matches375 || matches390 || matches390h) {
      return "43%"
    }

    if (matches600) {
      return "45%"
    }

    if (matches900) {
      return "45%"
    }

    if (matches1200) {
      return "45%"
    }
    return "40%"
  } else if (type === "middleLarge") {
    if (matches400 || matches375 || matches390) {
      return "43%"
    }
    if (matches414) {
      return "43%"
    }
    return "50%"
  } else if (type === "left") {
    if (matches390) {
      return "45%"
    }
    if (matches400 || matches375) {
      return "42%"
    }
    if (matches414) {
      return "42%"
    }
    if (matches414h) {
      return "25%"
    }
    if (matches820) {
      return "42%"
    }
    return "35%"
  } else if (type === "right") {
    if (matches390) {
      return "45%"
    }
    if (matches400 || matches375) {
      return "42%"
    }
    if (matches414) {
      return "44%"
    }
    if (matches414h) {
      return "42%"
    }
    if (matches820) {
      return "42%"
    }
    return "35%"
  }

  return "40%"
}

const useWidthSize = (type: string) => {
  const matches375 = useMediaQuery("(max-width:375px)")
  const matches390 = useMediaQuery("(max-width:390px)")
  const matches375h = useMediaQuery("(max-height:375px)")
  const matches400 = useMediaQuery("(max-width:400px)")
  const matches414 = useMediaQuery("(max-width:414px)")
  const matches414h = useMediaQuery("(max-height:414px)")
  const matches600 = useMediaQuery("(max-width:600px)")
  const matches667 = useMediaQuery("(max-width:667px)")
  const matches768 = useMediaQuery("(max-width:768px)")
  const matches820 = useMediaQuery("(max-width:820px)")
  const matches900 = useMediaQuery("(max-width:900px)")
  const matches1200 = useMediaQuery("(max-width:1200px)")

  if (matches375 || matches375h || matches390) {
    if (type === "centeredTop") {
      return "60%"
    }
    if (type === "right") {
      return "25%"
    }
    if (type === "centered") {
      return "70%"
    }
    if (type === "middle") {
      return "30%"
    }
    return "30%"
  }

  if (matches400 || matches375h) {
    if (type === "centeredTop") {
      return "60%"
    }
    if (type === "right") {
      return "25%"
    }
    if (type === "centered") {
      return "70%"
    }
    if (type === "middle") {
      return "35%"
    }
    return "30%"
  }

  if (matches414) {
    if (type === "centeredTop") {
      return "60%"
    }
    if (type === "right") {
      return "40%"
    }
    if (type === "centered") {
      return "80%"
    }
    if (type === "middle") {
      return "35%"
    }
    return "30%"
  }

  if (matches667) {
    if (type === "middleLarge") {
      return "40%"
    }
    if (type === "middle") {
      return "35%"
    }
    if (type === "centeredTop") {
      return "80%"
    }
    if (type === "centered") {
      return "60%"
    }
    return "30%"
  }

  if (matches414h) {
    if (type === "centeredTop") {
      return "60%"
    }
    if (type === "right") {
      return "15%"
    }
    if (type === "left") {
      return "15%"
    }
    return "30%"
  }

  if (matches600 || matches768 || matches820) {
    if (type === "middleLarge") {
      return "40%"
    }
    if (type === "middle") {
      return "35%"
    }
    if (type === "centeredTop") {
      return "80%"
    }
    if (type === "centered") {
      return "70%"
    }
    return "30%"
  }

  if (matches900) {
    if (type === "middleLarge") {
      return "40%"
    }
    if (type === "middle") {
      return "35%"
    }
    if (type === "centeredTop") {
      return "80%"
    }

    return "30%"
  }

  if (matches1200) {
    if (type === "middleLarge") {
      return "40%"
    }
    if (type === "middle") {
      return "35%"
    }
    if (type === "centeredTop") {
      return "80%"
    }
    if (type === "centered") {
      return "80%"
    }
    return "30%"
  }

  if (type === "centered") {
    return "60%"
  }
  if (type === "middle") {
    return "35%"
  }
  if (type === "middleLarge") {
    return "40%"
  }
  if (type === "centeredTop") {
    return "80%"
  }

  return "30%"
}

const useLeftPos = (type: string) => {
  const matches375 = useMediaQuery("(max-height:375px)")
  const matches400 = useMediaQuery("(max-width:400px)")
  const matches414h = useMediaQuery("(max-height:414px)")

  if (type === "centered") {
    return "50%"
  } else if (type === "centeredTop") {
    return "50%"
  } else if (type === "middle") {
    return "50%"
  } else if (type === "middleLarge") {
    return "50%"
  } else if (type === "left") {
    if (matches400 || matches375) {
      return "35%"
    }
    if (matches414h) {
      return "25%"
    }
    return "25%"
  } else if (type === "right") {
    if (matches400 || matches375) {
      return "5%"
    }
    if (matches414h) {
      return "12%"
    }
    return "-6%"
  }

  return "50%"
}

const MarkdownPositioned: React.FC<MarkdownPositionedProps> = ({
  position,
  text,
  color,
}) => {
  const fontSize = useFontSize()
  const top = useTopPos(position)
  const width = useWidthSize(position)
  const left = useLeftPos(position)

  if (position === "centered") {
    return (
      <MarkdownContainerCentered
        top={top}
        fontSize={fontSize}
        color={color}
        width={width}
        left={left}
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </MarkdownContainerCentered>
    )
  } else if (position === "centeredTop") {
    return (
      <MarkdownContainerCenteredTop
        top={top}
        fontSize={fontSize}
        color={color}
        width={width}
        left={left}
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </MarkdownContainerCenteredTop>
    )
  } else if (position === "left") {
    return (
      <MarkdownContainerLeft
        top={top}
        fontSize={fontSize}
        color={color}
        width={width}
        left={left}
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </MarkdownContainerLeft>
    )
  } else if (position === "right") {
    return (
      <MarkdownContainerRight
        top={top}
        fontSize={fontSize}
        color={color}
        width={width}
        left={left}
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </MarkdownContainerRight>
    )
  } else if (position === "middleLarge") {
    return (
      <MarkdownContainerMiddleLarge
        top={top}
        fontSize={fontSize}
        color={color}
        width={width}
        left={left}
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </MarkdownContainerMiddleLarge>
    )
  } else if (position === "credits") {
    return (
      <MarkdownContainerBottomLeft
        top={top}
        fontSize={fontSize}
        color={color}
        width={width}
        left={left}
      >
        <ReactMarkdown>{text}</ReactMarkdown>
      </MarkdownContainerBottomLeft>
    )
  }

  return (
    <MarkdownContainerMiddle
      top={top}
      fontSize={fontSize}
      color={color}
      width={width}
      left={left}
    >
      <ReactMarkdown>{text}</ReactMarkdown>
    </MarkdownContainerMiddle>
  )
}

export default MarkdownPositioned
