import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import styled from "styled-components"

const MuiCheckbox = styled(Checkbox)(({ theme }) => ({
  "&&": {
    "&.MuiCheckbox-root": {
      padding: "0 8px",
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "&.MuiCheckbox-colorSecondary": {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  },
}))

const MuiFormControlLabel = styled(FormControlLabel)(() => ({}))

export { MuiCheckbox, MuiFormControlLabel }
