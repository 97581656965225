import Paper from "@mui/material/Paper"
import styled from "styled-components"

const WrapperStyled = styled(Paper)`
  && {
    position: absolute;
    width: 500px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #ccc;
    padding: 2em;
  }
`

export default WrapperStyled
