import Link from "@mui/material/Link"
import styled from "styled-components"

const MuiLink = styled(Link)`
  color: ${(props) => props.theme.style.link.color};
  &:hover {
    color: ${(props) => props.theme.style.link.over};
  }

  && {
    &.MuiLink-root {
      color: ${(props) => props.theme.style.link.color};
      &:hover {
        color: ${(props) => props.theme.style.link.over};
      }
      &.MuiLink-underlineAlways {
        text-decoration-color: ${(props) => props.theme.style.link.color};
      }
    }
  }
` as typeof Link

export default MuiLink
