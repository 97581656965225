import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"

import styled from "styled-components"

const MuiTable = styled(Table)(({ theme: { style } }) => ({
  "&&": {
    borderSpacing: 0,
    borderCollapse: "collapse",
    backgroundColor: style.cardContent.backgroundColor,
  },
}))

const MuiTbody = styled(Tbody)(({ theme: { style } }) => ({
  "&&": {},
}))

const MuiTd = styled(Td)(({ theme: { style } }) => ({
  "&&": {
    padding: `${style.padding.small} ${style.padding.medium}`,
    "&.providers-email": {
      wordBreak: "break-all",
    },
  },
}))

const MuiThead = styled(Thead)(({ theme: { style } }) => ({
  "&&": {
    textAlign: "left",
  },
}))

const MuiTh = styled(Th)(({ theme: { style } }) => ({
  "&&": {
    backgroundColor: style.table.thead.backgroundColor,
    border: `1px solid ${style.table.borderColor}`,
    padding: `${style.padding.small} ${style.padding.medium}`,
  },
}))

const MuiTr = styled(Tr)(({ theme }) => ({
  "&&": {
    padding: 0,
    border: "none",
    paddingBottom: "8px",
    paddingTop: theme.breakpoints.down("md") ? `16px` : "0",
    "&:not(:first-of-type)": {
      marginTop: theme.breakpoints.down("md") ? `16px` : "0",
      borderTop: theme.breakpoints.down("md")
        ? `1px solid ${theme.style.card.borderColor}`
        : "none",
    },
  },
}))

export { MuiTable, MuiTbody, MuiTd, MuiTh, MuiThead, MuiTr }
