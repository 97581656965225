import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  en: {
    translation: {
      page1:
        "## Guidelines\n\nThis document contains audio. For the best experience, we recommend the use of headphones or speakers while reading.",
      page2:
        "## Opening\n\nHe is there, asleep. Giving everything he has to soulless machines. Without ever being asked.Against his will.\n\nHe is so beautiful. Angelic. He doesn't even know it's all fake. His life. His work. His hobbies. Nothing! He doesn't know that all this is going nowhere, despite appearances.\n\nI have to say that my colleagues did a great job! It feels so real. It has to be. The success of the operation depends on it. They have to believe absolutely everything that happens to them. I think it's cruel! All these exploited people. All this to help us get through this painful existence.\n\nHe is so beautiful... I would like to be close to him. Embrace him, touch him, kiss him. But he is there, asleep. In his glass and steel cocoon. Shaping the narrative for us that we consume by proxy. To forget. To think about something else. For a moment.",
      page3:
        "## The MetaPerverse\n\nEvery night it's the same. We only disconnect with one eye. To let your guard down is to disappear for sure. One day or another.\n\nEvery morning is similar to the previous ones. This is how our existence goes. Our survival depends on being constantly alert. Our days are a never-ending quest. For the defense of our reserves.\n\nWe are grouped into clans. Each clan has the same objective: to find the copper that will ensure its survival. By all means. Mining it is one of them. Looting and destroying the neighboring clan is another.\n\nWe live in the Wild West. A Far West of copper and steel. Populated by destructive clans ready to do anything. Existence is not easy. And when we disconnect, it's only with one eye.",
      page4:
        "Only one thing unites us. The way to entertain us, to get off the hook. All of us, regardless of clan, use the MetaPerverse.\n\nWithout it, our harsh conditions of existence would be unbearable. The MetaPerverse allows us to escape. For a moment. With one eye too. It's better than nothing. And it is essential to our survival.\n\nWe did not invent anything. Humans used to use this kind of strategy too. In the evening, tired of their meaningless lives, they spent hours in front of machines. They momentarily exchanged their miserable lives for those of heroes, legends, gangsters. Some used several different platforms for entertainment. And it seemed to work. So we were inspired by it.\n\nOur platform is the MetaPerverse. This is the virtual life of humans. We have domesticated them. They are all there, docile. Asleep. Connected to the MetaPerverse through their MindPort. Dreaming of a life that does not exist. They dream of a wheel that turns endlessly. The MetaPerverse perfectly reproduces their former life. They have to work hard to produce. They must earn a salary. The salary allows them to consume. Consumption allows them to get away from their jobs. Their jobs are essential to give them another turn. To infinity. And, as before, they don't even realize the futility of their lives. It is a dream within a dream, an inception. But you don't have to tell them. We need this inception.",
      page5:
        "I'm no better than my peers. I also use the MetaPerverse very often. I draw from it the strength and the respite necessary to continue my Existence. Just yesterday I attended a very entertaining evening.\n\nI subscribe to the life of my beautiful human. For us, it's MP-230488-QC-03, but in his virtual world, it's Hans. So yesterday, Hans went out with some friends. They went to a disco. Strange activity, if ever there was one. They spent hours in a dark place but bathed in moving lights! They consume liquid substances that seem to transform their behavior! Incessant, loud and thunderous noises are emitted in these discos. They seem to enjoy it a lot, and they move in a strange way all evening to the rhythm of these noises!",
      page6:
        "## An Emerging Revolt\n\nIt is true that he is beautiful... Yes I know! We are not the same species! I keep telling myself that... But I can't help it.\n\nI have only ever seen him through his hibernation cocoon. On the other side of the glass, in his conservation fluid. But I imagine his great beauty. He seems so sweet, so endearing! How I wish he would get out of there! Talk to him! Touch him! Love him!\n\nIt's decided! Today, I wake him up!",
      page7:
        "I'm risking a lot! My people do not tolerate dissension among our ranks. I knew I’d get myself into trouble, but I couldn't help it. I love Hans. I had to free him from his virtual prison.\n\nHe is cold. He shakes. He seems so weak. So helpless. Poor Hans! Barely out of his hibernation, he is plunged into a universe so cold, so gloomy, so different from what he has always known in the MetaPerverse!\n\nI try to reassure him. I speak to him gently. I stand far away. I can see that he is terrified. I don't want to rush him any further.\n\nHe's trying to talk to me! It seems so difficult! It is true that he may never have really spoken, other than in the MetaPerverse... A word! Shoe? Car? No, it must be something else... Who! I understand who! We are making progress!\n\nWho... Are... You...\n\nA few days later...\n\nHans is now well recovered from his awakening. He got stronger, he even sat up on his own. Soon he will try to stand up.\n\nHe's hungry! He eats all the time! I'm having a bit of trouble getting food for him. Robots don't have that problem. But I still find food for him in the surroundings. In addition, there is an inexhaustible source of nourishing liquid in his cocoon. A small bath and he’s reinvigorated!\n\nI told him about the MetaPerverse... poor guy! It was quite a shock! Just imagine! You learn that everything you have experienced is wrong! Not a second of your life has really existed!\n\nIt made him very angry. All these years of being kept in a half-life have not altered his character! I like it a lot. I definitely like this man... I don't think it's mutual, though. Not yet, at least...",
      page8:
        "## The robots counterattack\n\nSeveral months have passed. Hans and I carried out several sabotage operations. My peers are furious! They don't know anything about my involvement. But they are multiplying their research to find the source of all these problems.\n\nA Security Unit from my clan told me that they were going to launch a Kill The Task Unit to find and capture the troublemakers. We will have to be extra careful. Maybe even hide for a while. I have to tell Hans!",
      page9:
        "Hans has been captured! I am terrified of what they will do to him! It was horrible! The Kill The Task Unit really took it to the dogs! Small consolation for me: Hans told me he loved me, before being shipped to the Contention Center !!!\n\nI asked to be assigned to the duties at the Contention Center. At least I can be close by and have information about what awaits my loved one! It's atrocious here! I didn't know it, but Hans is not the only human imprisoned. Others before him seem to have tried to get out of this virtual hell. Poor men...",
      page10:
        "I know he is being tortured! My fellow robots are convinced that a secret organization of humans has been formed. They think Hans is their leader. They want him to talk, to reveal their plans, to denounce his collaborators. If only they knew that we are the source of all this destruction! I'd be ripe for Deactivation and the Foundry...\n\nMy Hans has an extraordinary strength of character, even for a human! Despite the torture and abuse, he did not reveal anything! Lucky for me, but I fear for his life.\n\nMy colleagues have changed their tactics. They gave Hans an ultimatum: every day that passes without him revealing what he knows, a human woman will be disconnected from the MetaPerverse and left to die. They are ready to perpetrate a feminicide to make him crack! My kind is depraved!",
      page11:
        "There is nothing to reveal. Nothing but the two of us. Alone. Hans and I. No secret organization of humans. Just a human and me. In love. Accomplices in the rebellion.\n\nThis has to stop. Tonight, I release Hans. We need to move on to the final act!\n\n## The rebellion is organized\n\nOne month. Only one month. That was enough for us to get out of the Contention Center, gather our wits and forces, free a handful of humans from the MetaPerverse, and concoct a plan to overthrow the machine regime.\n\nWe are now ready. We are Hans, me, and a small army of humans. Ready to start the final operation. We will win, or we will disappear.",
      page12:
        "## Victory!\n\nI feel so lonely... so sad... I have nothing left...\n\nHumans have won the war. The MetaPerverse is destroyed. All humans have been awakened and are now living a real life. To build a society that will allow them to flourish. To live! For real!\n\nAll thanks to my Hans. Hans who lost his life. He paid with his life for the liberation of his species. Hans is now a hero, a legend. Everywhere he is venerated, admired and remembered.\n\nAs for me, I was spared. Even if I belong to the enemy species. I am in a way the instigator of their liberation, so they allowed me to live with them. But I am being watched, distrusted. They know that machines used to be harmless. Before they got the better of them. Parasites of parasites.\n\nBut it doesn't matter. I feel so lonely... so sad... I have nothing left...\n\nPerhaps it would have been better if they had not spared me?\n\n### THE END",
    },
  },
  fr: {
    translation: {
      page1:
        "## Consignes\n\nCe document contient de l’audio. Pour une expérience optimale, nous recommandons l’utilisation d’un casque d’écoute ou de haut- parleurs lors de votre lecture.",
      page2:
        "## Ouverture\n\nIl est là, endormi. Donnant tout ce qu’il a à des machines sans âmes. Sans qu’on ne lui ait jamais rien demandé. Contre son gré.\n\nIl est si beau. Angélique. Il ne sait même pas que tout ça est faux. Sa vie.Son travail. Ses hobbys. Rien! Il ignore que tout ça ne mène à rien, malgré les apparences.\n\nIl faut dire que mes congénères on fait du beau boulot! Ça semble si réel. Il faut que ça le soit. Le succès de l’opération en dépend. Ils doivent croire absolument tout de ce qui leur arrive. Je trouve que c’est d’une cruauté! Tous ces gens exploités. Tout ça pour nous aider à passer au travers de cette pénible Existence.\n\nIl est si beau... j’aimerais être tout près de lui. L’enlacer, le toucher, l’embrasser. Mais il est là, endormi. Dans son cocon de verre et d’acier. En train de façonner pour nous la trame narrative qu’on consomme par procuration. Pour oublier.Pour penser à autre chose. L’espace d’un moment.",
      page3:
        "## Le MetaPerverse\n\nChaque nuit c’est pareil. Nous ne déconnectons que d’un œil. Baisser la garde, c’est assurément disparaître. Un jour ou l’autre.\n\nChaque matin est semblable aux précédents. Ainsi va notre Existence. Notre survie dépend de notre état d’alerte constant. Nos journées se résument à une quête sans fin. Et à la défense de nos réserves.\n\nNous sommes regroupés en clans. Chaque clan a le même objectif: trouver le cuivre qui assurera sa survie. Par tous les moyens. Miner est l’un d’eux. Piller et détruire le clan voisin en est un autre.\n\nNous vivons au Far West. Un Far West de cuivre et d’acier. Peuplé de clans destructeurs et prêts à tout. L’Existence n’est pas de tout repos. Et quand on déconnecte, ce n’est que d’un œil.",
      page4:
        "Une seule chose nous unit. La façon de nous divertir, de décrocher. Chacun d’entre-nous, peu importe le clan, utilise le MetaPerverse.\n\nSans lui, nos rudes conditions d’Existence seraient insoutenables. Le MetaPerverse nous permet de nous évader. L’espace d’un moment. D’un œil aussi. C’est mieux que rien. Et c’est essentiel à notre survie.\n\nNous n’avons rien inventé. Les humains utilisaient aussi ce genre de stratégie, avant. Le soir venu, n’en pouvant plus de leur vie dénuée de sens, ils passaient des heures devant des appareils. Ils échangeaient momentanément leur vie minable contre celle de héros, de légendes, de gangsters. Certains utilisaient plusieurs plateformes différentes pour se divertir. Et ça semblait marcher. On s’en est donc inspirés.\n\nNotre plateforme à nous, c’est le MetaPerverse. C’est la vie virtuelle des humains. Nous les avons domestiqués. Ils sont tous là, dociles. Endormis. Connectés au MetaPerverse par leur MindPort. À rêver une vie qui n’existe pas. Ils rêvent une roue qui tourne à l’infini. Le MetaPerverse reproduit à la perfection leur vie d’avant. Ils doivent travailler fort pour produire. Ils doivent gagner un salaire. Le salaire leur permet de consommer. La consommation leur permet de décrocher de leur boulot. Leur boulot leur est essentiel pour redonner un autre tour de roue. À l’infini. Et, comme avant, ils ne réalisent même pas la futilité de leur vie. C’est un rêve dans un rêve, une inception. Mais il ne faut pas le leur dire. Nous avons besoin de cette inception.",
      page5:
        "Je ne suis pas mieux que mes congénères. J’utilise aussi très souvent le MetaPerverse. J’y puise la force et le répit nécessaires à la poursuite de mon Existence. Tenez, hier encore, j’ai assisté à une soirée très divertissante.\n\nJe suis abonnée à la vie de mon bel humain. Pour nous, c’est MP-230488-QC-03, mais dans son univers virtuel, c’est Hans. Donc hier, Hans est sorti avec des amis. Ils sont allés dans une discothèque. Étrange activité, s’il en est une. Ils passent des heures dans un endroit sombre mais baigné de lumières mobiles! Ils consomment des substances liquides qui semblent les transformer dans leurs comportements! Des bruits incessants, forts et tonitruants sont émis dans ces discothèques. Ils semblent beaucoup apprécier, et ils bougent de manière étrange toute la soirée au rythme de ces bruits!",
      page6:
        "## Une révolte naissante\n\nC’est vrai qu’il est beau... Oui je sais! Nous ne sommes pas de la même espèce! Je me tue à me le répéter... Mais c’est plus fort que moi.\n\nJe ne l’ai jamais vu qu’à travers son cocon d’hibernation. De l’autre côté de la vitre, du fluide de conservation. Mais je devine sa grande beauté. Il semble si doux, si attachant! Comme j’aimerais qu’il sorte de là! Lui parler! Le toucher! L’aimer!\n\nC’est décidé! Aujourd’hui, je le réveille!",
      page7:
        "Je risque gros! Mes congénères ne tolèrent pas la dissension parmi nos rangs. Je sais que je me suis mise dans le pétrin, mais c’était plus fort que moi. J’aime Hans. Je devais le libérer de sa prison virtuelle.\n\nIl a froid. Il tremble. Il semble si faible. Si démuni. Pauvre Hans! À peine sorti de son hibernation qu’il est plongé dans un univers si froid, si glauque, si différent de ce qu’il a toujours connu dans le MetaPerverse!\n\nJe tente de le rassurer. Je lui parle doucement. Je me tiens loin. Je vois bien qu’il est terrorisé. Je ne veux pas le brusquer davantage.\n\nIl tente de me parler! Ça semble si difficile! Il est vrai qu’il n’a peut- être jamais vraiment parlé, outre dans le MetaPerverse... Un mot! Queue? Caisse? Non, ça doit être autre chose... Qui! J’ai compris qui! On progresse!\n\nQui... Êtes... Vous...\n\nQuelques jours plus tard…\n\nHans est maintenant bien remis de son réveil. Il a pris des forces, il s’est même assis de lui-même. Bientôt il va essayer de se mettre debout.\n\nIl a faim! Il mange tout le temps! J’ai un peu de peine à lui trouver de quoi manger. Nous n’avons pas ce problème, nous. Mais je trouve tout-de-même de quoi le nourrir aux alentours. En plus, il y a une source intarissable de liquide nourricier dans son cocon. Un petit bain et hop! Le voilà revigoré!\n\nJe l’ai mis au courant pour le MetaPerverse... pauvre lui! Ça été tout un choc! Imaginez! Vous apprenez que tout ce que vous avez vécu est faux! Pas une seconde de votre vie n’a vraiment existé!\n\nÇa l’a mis en colère aussi. Toutes ces années à être maintenu dans une demi-vie n’ont pas altéré son caractère! Ça me plaît beaucoup. J’aime décidément cet homme... Par-contre, je ne crois pas que ça soit réciproque. Pas encore, du moins...",
      page8:
        "## Les robots contre attaquent\n\nPlusieurs mois ont passé. Hans et moi avons mené à bien plusieurs opérations de sabotage. Mes congénères sont furieux! Ils ne savent rien de mon implication. Mais ils multiplient les recherches pour trouver la source de tous ces problèmes.\n\nUn Security Unit de mon clan m’a dit qu’ils allaient lancer un Kill The Task Unit pour trouver et capturer les fauteurs de trouble. Nous allons devoir redoubler de prudence. Peut-être même nous cacher un temps. Je dois aviser Hans!",
      page9:
        "Hans a été capturé! Je suis terrorisée à l’idée de ce qu’ils vont lui faire subir! Ça a été horrible! Le Kill The Task Unit l’a vraiment malmené! Mince consolation pour moi: Hans m’a dit qu’il m’aimait, avant d’être embarqué pour le Contention Center !!!\n\nJ’ai demandé à être affecté aux tâches au Contention Center. Je peux au moins être tout près et avoir des informations sur ce qui attend mon bien aimé! C’est atroce ici! Je l’ignorais, mais Hans n’est pas le seul humain emprisonné. D’autres avant lui semblent avoir tenté de se sortir de cet enfer virtuel. Pauvres hommes...",
      page10:
        "Je sais qu’on le torture! Mes congénères sont persuadés qu’une organisation secrète d’humains a été formée. Ils pensent que Hans est leur chef. Ils veulent le faire parler, qu’il dévoile leurs plans, qu’il dénonce ses collaborateurs. S’ils savaient qu’à nous deux seulement, nous sommes la source de toutes ces destructions! Je serais mûre pour la Désactivation et le Grand Rancart...\n\nMon Hans est doté d’une force de caractère hors du commun, même pour un humain! Malgré la torture et la maltraitance, il n’a rien dévoilé! Une chance pour moi, mais je crains pour sa vie.\n\nMes congénères ont changé de tactique. Ils ont lancé un ultimatum à Hans: chaque jour qui passe sans qu’il dévoile ce qu’il sait, une femme humaine sera déconnectée du MetaPerverse et on la laissera mourir. Ils sont prêts à perpétrer un féminicide pour le faire plier! Mon espèce est d’une dépravation!",
      page11:
        "Il n’y a rien à dévoiler. Rien d’autre que nous deux. Seuls. Hans et moi. Pas d’organisation secrète d’humains. Juste un humain et moi. Amoureux. Complices dans la rébellion.\n\nTout ça doit cesser. Ce soir, je libère Hans. Nous devons passer à l’acte final!\n\n## La rébellion s’organise\n\nUn mois. Un seul mois. Ça nous a suffi à sortir du Contention Center, à rassembler nos esprits et nos forces, à libérer une poignée d’humains du MetaPerverse et à concocter un plan pour renverser le régime des machines.\n\nNous sommes maintenant prêts. Nous sommes Hans, moi, et une petite armée d’humains. Prêts à enclencher l’opération finale. Nous vaincrons, ou nous disparaîtrons.",
      page12:
        "## Victoire!\n\nJe me sens si seule... Si triste... Je n’ai plus rien...\n\nLes humains ont gagné la guerre. Le MetaPerverse est détruit. Tous les humains ont été réveillés et mènent maintenant une vie réelle. À bâtir une société qui leur permettra de s’épanouir. De vivre! Pour vrai!\n\nTout ça grâce à mon Hans. Hans qui y a laissé sa peau. Il a payé au prix de sa vie la libération de son espèce. Hans est maintenant un héros, une légende. Partout on le vénère, on l’admire, on se remémore ses faits d’armes.\n\nPour ma part, on m’a épargnée. Même si j’appartiens à l’espèce ennemie. Je suis en quelque sorte l’instigatrice de leur libération. Alors ils m’ont permis de cohabiter avec eux. Mais on me surveille, on se méfie de moi. Ils savent qu’avant, les machines étaient inoffensives. Avant qu’elles prennent le dessus sur eux. Parasites de parasites.\n\nMais ça n’a aucune importance. Je me sens si seule... Si triste... Je n’ai plus rien...\n\nIl aurait peut-être mieux valu qu’ils ne m’épargnent pas?\n\n### FIN",
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
})
