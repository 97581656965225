import * as React from "react"

import { Menu, MenuItem } from "@mui/material"

import { IconButton } from "./NavbarLanguagesDropdown.styled"
import { IconFlag } from "../../icons/"
import { useTranslation } from "react-i18next"

type languageOptionsType = {
  [key: string]: {
    icon: any
    name: string
  }
}

const languageOptions: languageOptionsType = {
  en: {
    icon: "/assets/img/flags/us.png",
    name: "en",
  },
  fr: {
    icon: "/assets/img/flags/fr.png",
    name: "fr",
  },
}

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation()
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null)

  const selectedLanguage = languageOptions[i18n.language]

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
    closeMenu()
  }

  const k = Object.keys(languageOptions)

  return (
    <>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="large"
      >
        <IconFlag lang={selectedLanguage?.name} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {k.map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default NavbarLanguagesDropdown
