import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import styled from "styled-components"

const MuiSelect = styled(Select)(({ theme }) => ({
  "&&": {
    minWidth: "120px",
    margin: 0,
    "&.MuiInputBase-colorPrimary fieldset": {
      borderColor: theme.palette.fieldset.borderColor,
      color: theme.palette.fieldset.borderColor,
    },
  },
}))

const MuiInputLabel = styled(InputLabel)(({ theme }) => ({
  "&&": {
    fontSize: "12px",
    "&.MuiInputLabel-formControl": {
      top: "-6px",
    },
    "&.Mui-focused, &.MuiFormLabel-filled": {
      top: "3px",
      color: theme.palette.fieldset.labelColor,
    },
    color: theme.palette.fieldset.textColor,
  },
}))

export { MuiSelect, MuiInputLabel }
