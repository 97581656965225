import { NavLink } from "react-router-dom"
import { rgba } from "polished"
import styled from "styled-components"

import ListItemButton from "@mui/material/ListItemButton"
import MuiDrawer from "@mui/material/Drawer"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

import { Logo } from "@/components"
import { ItemType } from "./SidebarNavListItem"

const LogoWrapper = () => <Logo navbar width="64px" height="64px" />

const BrandIcon = styled(LogoWrapper)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: ${(props) => props.theme.sidebar.iconSize};
  height: ${(props) => props.theme.sidebar.iconSize};
`

const Drawer = styled(MuiDrawer)``

const Brand = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 100%;
`

const Link = styled(NavLink)`
  text-decoration: none;
`

const Item = styled(ListItemButton)<ItemType>`
  && {
    display: block;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    svg {
      color: ${(props) => props.theme.sidebar.color};
      font-size: ${(props) => props.theme.sidebar.iconSize};
      width: ${(props) => props.theme.sidebar.iconSize};
      height: ${(props) => props.theme.sidebar.iconSize};
      opacity: 0.8;
      display: block;
      margin: 0 auto;
    }

    &:hover {
      background-color: #404040;
    }
    span {
      color: ${(props) => props.theme.sidebar.color};
      display: block;
    }
    &.${(props) => props.activeClassName} {
      background-color: #404040;
    }
  }
`

const Title = styled.div`
  font-size: 13px;
  display: block;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-top: 8px;
`

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`

export {
  Brand,
  BrandIcon,
  Drawer,
  ExpandLessIcon,
  ExpandMoreIcon,
  Item,
  Link,
  Title,
}
