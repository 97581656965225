import styled from "styled-components"

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;

  ${(props) => props.theme.breakpoints.down("md")} {
    display: block;
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${(props) => props.theme.style.borderColor};
  }
`

const Li = styled.li`
  margin: 0;
  padding: ${(props) =>
    props.theme.style.padding.tiny + " " + props.theme.style.padding.small};
  display: inline-block;

  ${(props) => props.theme.breakpoints.down("md")} {
    display: block;
  }
`

export { Ul, Li }
