import {
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
} from "./Card.styled"

import { CardProps as MuiCardProps } from "@mui/material/Card"

export interface CardProps extends MuiCardProps {
  title?: string
  footerActions?: any
  headerActions?: any
  contentStyles?: any
  headerStyles?: any
}

export const Card = (props: CardProps) => {
  const { title, footerActions, headerActions, contentStyles, headerStyles } =
    props
  return (
    <MuiCard {...props}>
      {title && (
        <MuiCardHeader sx={headerStyles} title={title} action={headerActions} />
      )}
      <MuiCardContent sx={contentStyles}>{props.children}</MuiCardContent>
      {footerActions && <MuiCardActions>{footerActions}</MuiCardActions>}
    </MuiCard>
  )
}

Card.defaultProps = {
  elevation: 0,
}

export default Card
