import MuiAppBar from "@mui/material/AppBar"
import MuiIconButton from "@mui/material/IconButton"
import InputBase from "@mui/material/InputBase"
import { darken } from "polished"
import styled from "styled-components"

const AppBar = styled(MuiAppBar)`
  && {
    background: #fff;
    color: ${(props) => props.theme.header.color};
  }
`

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const Search = styled.div(({ theme }) => ({
  "&&": {
    borderRadius: "2px",
    backgroundColor: theme.header.background,
    display: theme.breakpoints.up("md") ? "block" : "none",
    position: "relative",
    width: "100%",

    "&:hover": {
      backgroundColor: darken(0.05, theme.header.background),
    },
  },
}))

const SearchIconWrapper = styled.div`
  width: 32px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }
`
const Input = styled(InputBase)(({ theme }) => ({
  "&&": {
    color: "inherit",
    width: "100%",
    "> input": {
      border: `2px solid ${theme.palette.fieldset.borderColor}`,
      borderRadius: "3px",
      color: theme.header.search.color,
      padding: "4px 16px 4px 28px",
      width: 190,
      "&[type='search']": {
        fontSize: 12,
      },
    },
  },
}))

export { AppBar, IconButton, Input, Search, SearchIconWrapper }
