import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { ButtonProps } from "@mui/material/Button"
import MuiButton from "../button/Button.styled"
import MuiLink from "../link/Link.styled"

export interface Props extends ButtonProps {
  href: string
}

const MyLink = React.forwardRef<any, any>((props, ref) => (
  <RouterLink ref={ref} to={props.href} style={{ textDecoration: "none" }}>
    {props.children}
  </RouterLink>
))

const LinkButton = (props: Props) => {
  // TODO: Fix color typing
  const { href, children, ...rest } = props

  return (
    <MuiLink style={{ textDecoration: "none" }} href={href} component={MyLink}>
      <MuiButton variant="contained" {...rest}>
        {children}
      </MuiButton>
    </MuiLink>
  )
}

export default LinkButton
