import styled from "styled-components"

const BannerBlock = styled.header`
  width: 100%;
  position: relative;
  margin: 0;
  overflow: hidden;
  display: block;
  color: #222;
  fill: #222;
  overflow-x: hidden;
  font-size: 16px;
`

const OnlySemantic = styled.h1`
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  color: #222;
  fill: #222;
  font-size: 16px;
`

const BannerBlockSpacer = styled("div")<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    height: isMobile ? "0" : "20px",
    width: "100%",
    color: "#222",
    fill: "#222",
    fontSize: "16px",
  })
)

const BannerImage = styled("div")<{ isMobile: boolean }>(({ isMobile }) => ({
  backgroundImage: isMobile ? "unset" : "url(./assets/img/large_blur.jpg)",
  backgroundColor: "#143B3E",
  position: "absolute",
  top: "-3%",
  left: "-3%",
  width: "106%",
  height: "106%",
  backgroundSize: "cover",
  overflow: "hidden",
  zIndex: "-1",
  backgroundPosition: "center",
  color: "#222",
  fill: "#222",
  fontSize: "16px",
}))

const AlbumCoverWrapper = styled.div`
  width: 100%;
  max-width: 850px;
  margin: auto;
  color: #222;
  fill: #222;
  font-size: 16px;
`

const AlbumCover = styled("div")<{ isMobile: boolean }>(({ isMobile }) => ({
  borderColor: "rgba(255, 255, 255, 0)",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  width: isMobile ? "360px" : "400px",
  height: isMobile ? "360px" : "400px",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  border: isMobile ? "15px solid #143B3E" : "15px solid rgba(0, 0, 0, 0.5)",
  marginLeft: isMobile ? "calc((100% - 360px) / 2)" : "27%",
  boxSizing: "border-box",
  position: "relative",
  fontSize: "16px",
}))

const CoverPlayOverlay = styled.div`
  opacity: 0;
  position: absolute;
  width: 370px;
  height: 370px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  transition: opacity 0.5s;
  font-size: 16px;
`

const AlbumCoverImage = styled.img`
  width: 100%;
  color: #222;
  fill: #222;
  font-size: 16px;
`

export {
  BannerBlock,
  OnlySemantic,
  BannerBlockSpacer,
  BannerImage,
  AlbumCoverWrapper,
  AlbumCover,
  CoverPlayOverlay,
  AlbumCoverImage,
}
