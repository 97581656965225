import React from "react"
import styled, { css } from "styled-components"
import ReactPerfectScrollbar from "react-perfect-scrollbar"
import useMediaQuery from "@mui/material/useMediaQuery"
import useTheme from "@mui/material/styles/useTheme"

import { SidebarItemsType } from "@/types/sidebar"
import SidebarNavSection from "./SidebarNavSection"

const baseScrollbar = css`
  background-color: ${(props) => props.theme.sidebar.background};
`

const Scrollbar = styled.div`
  ${baseScrollbar}
`

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`

type SidebarNavProps = {
  items: {
    title: string
    pages: SidebarItemsType[]
  }[]
}

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType

  return (
    <ScrollbarComponent>
      {items &&
        items.map((item) => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
          />
        ))}
    </ScrollbarComponent>
  )
}

export default SidebarNav
