import { IconButton as MuiIconButton } from "@mui/material"
import styled from "styled-components"

const IconButton = styled(MuiIconButton)`
  svg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`

export { IconButton }
