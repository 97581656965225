import MuiSvgIcon from "./SvgIcon.styled"
import { SvgIconProps } from "@mui/material/SvgIcon"

const SvgIcon = (props: SvgIconProps) => {
  return <MuiSvgIcon {...props}>{props.children}</MuiSvgIcon>
}

SvgIcon.defaultProps = {}

export default SvgIcon
