import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled"

import TabPanelUnstyled from "@mui/base/TabPanelUnstyled"
import TabsListUnstyled from "@mui/base/TabsListUnstyled"
import TabsUnstyled from "@mui/base/TabsUnstyled"
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled"
import styled from "styled-components"

const borderRadius = "7px"

const Tab = styled(TabUnstyled)`
  background-color: transparent;
  border: none;
  color: #646464;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin: 0;
  padding: 8px 16px;
  width: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 1.25rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  &:first-of-type {
    border-radius: ${borderRadius} 0 0 ${borderRadius};
    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: ${borderRadius} ${borderRadius} 0 0;
    }
  }
  &:last-child {
    border-radius: 0 ${borderRadius} ${borderRadius} 0;
    ${(props) => props.theme.breakpoints.down("sm")} {
      border-radius: 0 0 ${borderRadius} ${borderRadius};
    }
  }

  &:not(:first-of-type) {
    ${(props) => props.theme.breakpoints.up("sm")} {
      border-left: 1px solid #e0e0e0;
    }
  }
  // Manage when there is only one Tab
  &:first-of-type&:last-of-type {
    border-radius: ${borderRadius};
  }

  &:hover,
  &:focus {
    color: #000;
    background-color: #fff;
    transition: color 0.5s, background-color 0.5s;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #222;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const MuiTabsUnstyled = styled(TabsUnstyled)`
  && {
    position: relative;
  }
`

const TabPanel = styled(TabPanelUnstyled)``

const TabsList = styled(TabsListUnstyled)`
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: ${borderRadius};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  max-width: 740px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    display: block;
    max-width: 100%;
  }
`

const ActionsWrapper = styled.div`
  ${(props) => props.theme.breakpoints.up("sm")} {
    position: absolute;
    text-align: right;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
  }
`

export { ActionsWrapper, MuiTabsUnstyled, Tab, TabPanel, TabsList }
