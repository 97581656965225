import styled from "styled-components"

const LogoStyled = styled.div`
  .cls-1 {
    isolation: isolate;
  }
  .cls-2 {
    fill: ${(props) => props.theme.palette.logo.color};
  }
  .cls-3 {
    mix-blend-mode: multiply;
  }
  .cls-4 {
    fill: url(#Fade_to_Black);
  }
  .cls-5 {
    fill: ${(props) => props.theme.palette.logo.textColor};
  }
`

const LogoStyledNavBar = styled.div`
  background-color: #e61b00;
  display: flex;
  font-size: 0;
  padding-left: 20px;
  padding-top: 8px;
  width: 110%;
  .cls-1 {
    isolation: isolate;
  }
  .cls-2 {
    fill: ${() => "#fff"};
  }
  .logo-text {
    mix-blend-mode: multiply;
  }
  .cls-4 {
    fill: url(#Fade_to_Black);
  }
  .cls-5 {
    fill: ${() => "#fff"};
  }
`

export { LogoStyled, LogoStyledNavBar }
