import React from "react"
import styled from "styled-components"

export interface Props {
  googleDriveId: string
}

const Mp3Container = styled.p`
  text-align: center;
`

const Audio = styled.audio`
  max-height: 80%;
  max-width: 80%;
  margin: auto;
  object-fit: contain;
`

export const GoogleDriveMp3: React.FC<Props> = ({ googleDriveId }) => {
  const src = `https://drive.google.com/uc?export=open&id=${googleDriveId}`
  return (
    <Mp3Container>
      <Audio controls autoPlay>
        <source src={src} type="audio/mpeg" />
        Votre navigateur ne supporte pas l'élément audio.
      </Audio>
    </Mp3Container>
  )
}

export default GoogleDriveMp3
